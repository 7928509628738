import { render, staticRenderFns } from "./QuizPreviewPopup.vue?vue&type=template&id=ef0aa7c4&scoped=true&"
import script from "./QuizPreviewPopup.vue?vue&type=script&lang=ts&"
export * from "./QuizPreviewPopup.vue?vue&type=script&lang=ts&"
import style0 from "./QuizPreviewPopup.vue?vue&type=style&index=0&id=ef0aa7c4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef0aa7c4",
  null
  
)

export default component.exports