import { render, staticRenderFns } from "./QuizDetailConfirm.vue?vue&type=template&id=116d2153&scoped=true&"
import script from "./QuizDetailConfirm.vue?vue&type=script&lang=ts&"
export * from "./QuizDetailConfirm.vue?vue&type=script&lang=ts&"
import style0 from "./QuizDetailConfirm.vue?vue&type=style&index=0&id=116d2153&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "116d2153",
  null
  
)

export default component.exports