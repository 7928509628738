
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { namespace } from 'vuex-class'
  import { QuizInterface, UserInterface } from '@/interface/BaseInterface'

  const tAuth = namespace('tAuth')

  @Component
  export default class QuizDetailConfirm extends Vue {
    @Prop({ required: true, type: Object }) quiz!: QuizInterface

    @tAuth.State('user') user!: UserInterface

    handleConfirm() {
      this.$emit('confirm')
    }
  }
