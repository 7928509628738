
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { QuestionInterface, AttemptResultInterface } from '@/interface/BaseInterface'
  import { forEach, size } from 'lodash'
  import QuizAttempt from '../../api/quizAttempt'
  import getApi from '@/api'

  const quizAttempt: QuizAttempt = getApi('quizAttempt')

  @Component({ components: {} })
  export default class ViewAttempt extends Vue {
    @Prop({ required: false, type: String, default: 'other' }) type!: 'reading' | 'listening' | 'other' | 'writing' | 'speaking'
    @Prop({ required: true, type: Array }) listQuestion!: QuestionInterface[]
    @Prop({ required: true, type: Array }) data!: AttemptResultInterface[]
    @Prop({ required: false, type: Number, default: 0 }) numberQuestions!: number

    resultData: Array<Record<string, any>> = []
    allQuestion: Array<{ index: number; questionId: string; key: string }> = []

    height = 800

    isLoading = false

    getListQuestion() {
      this.allQuestion = []
      let index = 1
      forEach(this.listQuestion, value => {
        if (size(value.keys) > 0) {
          forEach(value.keys, elm => {
            this.allQuestion.push({
              index: index,
              questionId: value._id,
              key: elm
            })
            index++
          })
        } else {
          this.allQuestion.push({
            index: index,
            questionId: value._id,
            key: ''
          })
          index++
        }
      })
    }

    // get getResultData() {
    //   const result: Array<Record<string, any>> = []
    //   forEach(this.data, value => {
    //     const a: Record<string, any> = {
    //       name: value.user?.firstName + ' ' + value.user?.lastName,
    //       id: value._id
    //     }

    //     result.push(a)
    //   })
    //   return result
    // }

    async handleOpen() {
      try {
        this.isLoading = true
        await this.getListQuestion()
        await this.loadingQuestion2()
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }

    loadingQuestion() {
      this.resultData = []
      forEach(this.data, async value => {
        const a: Record<string, any> = {
          name: value.user?.firstName + ' ' + value.user?.lastName,
          numberRightAnswers: value.numberRightAnswers,
          grade: value.grade,
          _id: value._id
        }
        try {
          const result = await quizAttempt.attemptDetailByUser(this.quizId, value._id, value.userId)
          forEach(result, elm => {
            a[elm.questionId] = elm.isCorrect
          })
        } catch (error) {
          console.log(error)
        }

        this.resultData.push(a)
      })
    }

    checkResult(result: boolean | Record<string, boolean>, key?: string) {
      if (!result) return false
      if (typeof result === 'boolean') {
        return result
      }
      return key ? !!result[key] : false
    }

    async loadingQuestion2() {
      try {
        this.resultData = []
        const result = await quizAttempt.allListResult(this.quizId)
        console.log(result)
        forEach(result, value => {
          const a: Record<string, any> = {
            name: value.user?.firstName + ' ' + value.user?.lastName,
            numberRightAnswers: value.numberRightAnswers,
            grade: value.grade,
            _id: value._id
          }
          forEach(value.result, elm => {
            a[elm.questionId] = elm.isCorrect
          })
          this.resultData.push(a)
        })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    handleClose() {
      this.reset()
    }

    reset() {
      console.log('reset')
    }

    mounted() {
      this.height = window.innerHeight - 130
    }
  }
