
  import { Vue, Component } from 'vue-property-decorator'
  import { QuizInterface, QuestionInterface, QuestionType } from '@/interface/BaseInterface'
  import QuizApi from '../../api/quizApi'
  import getApi from '@/api'
  import { assign, forEach } from 'lodash'
  import MyAttempt from '../../components/attempt/MyAttempt.vue'
  import AllAttempt from '../../components/attempt/AllAttempt.vue'
  import ViewAttempt from '../../components/attempt/ViewAttempt.vue'
  import QuizPreviewPopup from './QuizPreviewPopup.vue'
  import { SPopupBase } from '@/store/base'
  import { namespace } from 'vuex-class'

  const quizApi: QuizApi = getApi('quiz')
  const tBase = namespace('base')

  @Component({
    components: { MyAttempt, AllAttempt, QuizPreviewPopup, ViewAttempt }
  })
  export default class QuizDetail extends Vue {
    @tBase.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void

    quiz: QuizInterface = {
      _id: this.quizId,
      name: '',
      courseId: this.courseId,
      sectionId: '',
      parts: []
    }

    isRefresh = true

    tableData = []

    listQuestion: QuestionInterface[] = []

    isInitDetail = false

    get getPoint() {
      let point = 0
      forEach(this.quiz.parts, value => {
        forEach(value.questions, elm => {
          // @ts-ignore
          const a = parseInt(elm.point) || 0
          point += a
        })
      })
      return point
    }

    isLoading = false

    get checkStatus(): string {
      const now = new Date()
      if (!this.quiz.startDate || !this.quiz.endDate) {
        return 'close'
      }

      const startDate = new Date(this.quiz.startDate)
      if (this.daysBetween(now, startDate) < 0) {
        return 'close'
      }

      const endDate = new Date(this.quiz.endDate)
      if (this.daysBetween(now, endDate) > 0) {
        return 'coming'
      }

      return 'open'
    }

    daysBetween(first: Date, second: Date): number {
      // Copy date parts of the timestamps, discarding the time parts.
      const one = new Date(first.getFullYear(), first.getMonth(), first.getDate(), 0, 0, 0, 0)
      const two = new Date(second.getFullYear(), second.getMonth(), second.getDate(), 0, 0, 0)

      return one.getTime() - two.getTime()
    }

    async init() {
      try {
        this.isLoading = true
        this.quiz = await quizApi.getById(this.quizId)
        // const questions = await quizApi.getQuestionOverview(this.quizId)
        // this.quiz = assign(this.quiz, questions)
        // this.listQuestion = this.getListQuestion()
        this.isLoading = false
      } catch (error) {
        console.log(error)
      }
    }

    getListQuestion() {
      let result: QuestionInterface[] = []
      forEach(this.quiz.parts, value => {
        result = [...result, ...this.getQuestionOfArray(value.questions || [])]
      })
      return result
    }

    getQuestionOfArray(questions: QuestionInterface[]) {
      let result: QuestionInterface[] = []
      forEach(questions, value => {
        if (value.type === QuestionType.TEXT_ONLY) {
          result = [...result, ...this.getQuestionOfArray(value.childQuestions || [])]
        } else {
          result.push(value)
        }
      })
      return result
    }

    handleEdit(type: 'edit' | 'question') {
      if (type === 'edit') {
        this.$router.push({ name: 'quizEdit', params: { id: this.courseId, quizId: this.quizId } })
      } else {
        this.$router.push({ name: 'quizAddQuestion', params: { id: this.courseId, quizId: this.quizId } })
      }
    }

    handlePreview(isPreview = false) {
      // this.$router.push({ name: 'quizPreview', params: { id: this.courseId, quizId: this.quizId } })
      this.setOpenPopup({ module: 'quizPreviewPopup', isOpen: true })
      if (this.$refs.preivewPopup) {
        // @ts-ignore
        this.$refs.preivewPopup.setData(this.quizId, isPreview)
      }
    }

    async handleViewAttempt() {
      if (!this.isInitDetail) {
        console.log(this.isInitDetail)
        const questions = await quizApi.getQuestion(this.quizId)
        this.quiz = assign(this.quiz, questions)
        this.listQuestion = this.getListQuestion()
        this.isInitDetail = true
      }
      this.setOpenPopup({ module: 'viewAttempt', isOpen: true })
      // if (this.$refs.viewAttempt) {
      //   // @ts-ignore
      //   this.$refs.viewAttempt.setData(this.listQuestion)
      // }
    }

    handleRefresh() {
      console.log('refresh')
      this.isRefresh = false

      this.$nextTick(() => {
        this.isRefresh = true
      })
    }

    created() {
      this.setOpenPopup({ module: 'viewAttempt', isOpen: false })
      this.init()
    }
  }
