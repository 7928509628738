
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuizInterface, UserInterface, AnswerSubmitInterface, AttemptInterface } from '@/interface/BaseInterface'
  import QuizApi from '../../api/quizApi'
  import getApi from '@/api'
  import { assign, forEach, groupBy, size, find, filter } from 'lodash'
  import { namespace } from 'vuex-class'
  import QuizDetailConfirm from '../../components/quizPreview/QuizDetailConfirm.vue'
  import QuizTestSound from '../../components/quizPreview/QuizTestSound.vue'
  import QuizTest from '../../components/quizPreview/QuizTest.vue'
  import QuizAttempt from '../../api/quizAttempt'
  import { SPopupBase } from '@/store/base'

  const quizApi: QuizApi = getApi('quiz')
  const quizAttempt: QuizAttempt = getApi('quizAttempt')
  const tAuth = namespace('tAuth')
  const tBase = namespace('base')

  @Component({
    components: { QuizDetailConfirm, QuizTestSound, QuizTest }
  })
  export default class QuizPreview2 extends Vue {
    @Prop({ required: true, type: Boolean }) isPreview!: boolean
    @tBase.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void

    @tAuth.State('user') user!: UserInterface

    quiz: QuizInterface = {
      _id: this.quizId,
      name: '',
      courseId: this.courseId,
      sectionId: '',
      parts: []
    }

    quizStatus: AttemptInterface = {
      status: 'start',
      startTime: new Date().getTime(),
      finishTime: 0,
      attemptId: null,
      numberAttempts: 0
    }

    isLoading = false
    startTest = false
    isStartTest = false
    step = 1

    countDownTime = 0

    loop: any = null

    get getSound() {
      return find(this.quiz.materials, value => {
        return value.type === 'audio'
      })
    }

    get getFile() {
      return filter(this.quiz.materials, value => {
        return value.type !== 'audio'
      })
    }

    get allTime() {
      let allTime = this.quiz.timeLimit ? parseInt(this.quiz.timeLimit as string) : 0
      allTime = allTime * 60 * 1000
      return allTime
    }

    async init() {
      try {
        this.isLoading = true
        // const [quiz, questions] = await Promise.all([quizApi.getById(this.quizId), quizApi.getQuestion(this.quizId)])
        // this.quiz = quiz
        // this.quiz = await quizApi.getById(this.quizId)
        // const questions = await quizApi.getQuestion(this.quizId)
        if (!this.isPreview) {
          const [quiz, questions, status] = await Promise.all([quizApi.getById(this.quizId), quizApi.getQuestion(this.quizId), quizAttempt.quizAttemptStatus(this.quizId)])
          this.quizStatus = status
          this.quiz = assign(quiz, questions)
          this.isLoading = false
          if (this.quizStatus.status === 'in_process') {
            // this.handleContinute()
          }
        } else {
          const [quiz, questions] = await Promise.all([quizApi.getById(this.quizId), quizApi.getQuestion(this.quizId)])
          this.quiz = assign(quiz, questions)
          this.isLoading = false
        }
      } catch (error) {
        this.isLoading = false
      }
    }

    handleClickConfirm() {
      if (this.getSound) {
        this.step = 2
      } else {
        this.step = 3
      }
    }

    playAudio() {
      if (this.getSound) {
        // const audio = document.getElementById('audioFile') as HTMLAudioElement
        // audio.play()
        if (this.$refs.audio) {
          //@ts-ignore
          this.$refs.audio.play()
        }
      }
    }

    stopAudio() {
      if (this.getSound) {
        // const audio = document.getElementById('audioFile') as HTMLAudioElement
        // audio.pause()
        // audio.currentTime = 0
        if (this.$refs.audio) {
          //@ts-ignore
          this.$refs.audio.pause()
        }
      }
    }

    handleContinute() {
      this.startTest = true
      this.playAudio()
      this.handleLoop()
    }

    async handleStartTest() {
      try {
        if (this.startTest) return
        if (!this.isPreview) {
          if (this.isStartTest) return
          this.isStartTest = true
          const a = await quizAttempt.attemptQuiz(this.quizId)
          this.quizStatus.attemptId = a._id
          this.isStartTest = false
          // saveLocal(this.quizId, a)
        }
        this.startTest = true
        this.quizStatus.status = 'in_process'
        this.quizStatus.startTime = new Date().getTime()
        this.countDownTime = this.allTime
        this.playAudio()
        this.handleLoop()
      } catch (error) {
        this.isStartTest = false
        clearInterval(this.loop)
        if (error.code === 400) {
          this.$message.error(error.message)
        }
      }
    }

    handleLoop() {
      clearInterval(this.loop)
      this.loop = setInterval(() => {
        this.countDownTime -= 1000
        if (this.countDownTime < 0) {
          clearInterval(this.loop)
          this.handleSubmit()
        } else if (this.countDownTime < 6 * 1000 && this.countDownTime > 0) {
          this.$message({
            showClose: true,
            message: `Quiz will be submitted in ${Math.floor(this.countDownTime / 1000)} seconds`,
            type: 'warning',
            duration: 1000
          })
        }
      }, 1000)
    }

    async handleSubmit() {
      try {
        this.stopAudio()
        this.setOpenPopup({
          module: 'quizPreviewPopup',
          isOpen: false
        })
        if (this.$refs.quizTest) {
          // @ts-ignore
          const result = this.$refs.quizTest.submit()

          const groupResult = groupBy(result, value => {
            return value.questionId
          })

          const answers: AnswerSubmitInterface = {}
          forEach(groupResult, (value: any, key: string) => {
            if (size(value) === 1) {
              if (value[0].key) {
                const a: Record<string, any> = []
                a[value[0].key] = value[0].result
                answers[key] = assign({}, a)
              } else {
                answers[key] = value[0].result
              }
            } else {
              const b: Record<string, any> = {}
              answers[key] = []
              forEach(value, (elm: any, index: number) => {
                console.log('elm', elm)
                if (elm.key) {
                  b[elm.key] = elm.result
                } else {
                  b[index] = elm.result
                }
              })

              answers[key] = b
            }
          })
          console.log(answers)
          if (!this.isPreview) {
            await quizAttempt.submit(this.quizId, this.quizStatus.attemptId || '', answers)
          }

          this.$notify({
            title: 'Success',
            message: 'Submit successfully!',
            type: 'success'
          })
        }
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: 'Submit error!',
          type: 'error'
        })
        throw error
      }
    }

    created() {
      this.init()
      // this.handleStartTest()
    }

    mounted() {
      window.onbeforeunload = function(event: Event) {
        console.log('event', event)
        return confirm('Confirm refresh')
      }
    }

    beforeDestroy() {
      window.removeEventListener('onbeforeunload', () => {
        return 1
      })
      clearInterval(this.loop)
    }
  }
