
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuizInterface, PartInterface, QuestionInterface, QuestionType } from '@/interface/BaseInterface'
  import QuizApi from '../../api/quizApi'
  import getApi from '@/api'
  import QuestionPreview from '../../../questions/components/question/QuestionPreview.vue'
  import { forEach, size, findIndex, split, indexOf, filter } from 'lodash'

  const quizApi: QuizApi = getApi('quiz')

  @Component({
    components: { QuestionPreview }
  })
  export default class QuizTest extends Vue {
    @Prop({ required: true, type: Object }) quiz!: QuizInterface

    parts: PartInterface[] = []

    selectPart = 0
    selectIndex = 1
    totalPart = 0
    totalQuestions = 0

    async init() {
      const result = await quizApi.getQuestion(this.quizId)
      this.parts = result.parts
      this.totalPart = this.parts.length - 1
      this.totalQuestions = 0
      forEach(this.parts, value => {
        value.numQuestion = this.getTotalQuestion(value.questions)
        this.totalQuestions = this.totalQuestions + value.numQuestion
      })
      // this.parts = this.quiz.parts
      // this.addEventToQuestions()
    }

    getIndex(part: number, index: number) {
      let num = index + 1

      if (part === 0) {
        return index + 1
      }
      forEach(this.parts, (value, key) => {
        if (key < part) {
          num = num + (value.numQuestion || 0)
        }
      })
      return num
    }

    getIndexForQuestion(part: number, index: number) {
      let num = 0
      let questions: QuestionInterface[] = []

      forEach(this.parts, (value, key) => {
        if (key < part) {
          num = num + (value.numQuestion || 0)
        }
        if (key === part) {
          questions = value.questions || []
        }
      })

      forEach(questions, (value, key) => {
        if (index <= key) {
          return false
        }

        if (value.type === QuestionType.TEXT_ONLY) {
          num = num + this.getTotalQuestion(value.childQuestions)
        } else {
          const a = size(value.keys)
          num = num + (a === 0 ? 1 : a)
        }
      })

      return num + 1
    }

    getSpan(question: QuestionInterface) {
      // if (question.type === QuestionType.FILL_IN_MULTIPLE || question.type === QuestionType.MUTILPLE_DROPDOWN) {
      if (indexOf([QuestionType.FILL_IN_MULTIPLE, QuestionType.MUTILPLE_DROPDOWN, QuestionType.TEXT_ONLY, QuestionType.ESSAY], question.type) !== -1) {
        return 24
      }
      return 12
    }

    getTotalQuestion(questions: QuestionInterface[] | undefined) {
      if (!questions) {
        return 0
      }
      let total = 0
      forEach(questions, value => {
        if (value.type === QuestionType.TEXT_ONLY) {
          total = total + this.getTotalQuestion(value.childQuestions)
        } else {
          const a = size(value.keys)
          // console.log(value._id, a)
          total = total + (a === 0 ? 1 : a)
        }
      })
      return total
    }

    goToQuestion(part: PartInterface, partIndex: number) {
      const index = findIndex(this.parts, value => {
        return part._id === value._id
      })

      this.selectPart = index === -1 ? 0 : index
      this.selectIndex = this.getIndex(this.selectPart, partIndex) - 1

      setTimeout(() => {
        const element = document.getElementById(`question_${this.selectIndex}`)
        element?.focus()
        element?.scrollIntoView()
      }, 100)
    }

    // addEventToQuestions() {
    //   console.log()
    //   for (let i = 1; i < this.totalQuestions; i++) {
    //     const a = document.getElementById(`question_${i}`)
    //     console.log('123', i, a)
    //     a?.addEventListener('focus', (event: Event) => {
    //       console.log(event)
    //     })
    //   }
    // }

    // removeEventFromQuestions() {
    //   console.log()
    //   for (let i = 1; i < this.totalQuestions; i++) {
    //     const a = document.getElementById(`btn-${i}`)
    //     a?.removeEventListener('focus', () => {
    //       console.log(a)
    //     })
    //   }
    // }

    nextPart() {
      this.selectPart = this.selectPart + 1

      if (this.selectPart > this.totalPart) {
        this.selectPart = 0
      }
      this.selectIndex = this.getIndex(this.selectPart, 1) - 1
    }

    prevPart() {
      this.selectPart = this.selectPart - 1
      this.selectIndex = this.selectIndex - 1
      if (this.selectPart < 0) {
        this.selectPart = this.totalPart
      }
      this.selectIndex = this.getIndex(this.selectPart, 1) - 1
    }

    submit() {
      const from = document.getElementById('qizzFrom') as HTMLFormElement
      const allInput = from.querySelectorAll('input')
      let result: any[] = []
      forEach(allInput, (value: any) => {
        if (value.type === 'radio' && value.checked) {
          // console.log('select', value.value)
          result.push(this.getResult(value.name, value.value))
        } else if (value.type === 'checkbox' && value.checked) {
          // console.log('select', value.value)
          result.push(this.getResult(value.name, value.value))
        } else if (value.type !== 'radio' && value.type !== 'checkbox') {
          result.push(this.getResult(value.name, value.value))
        }
      })

      const allSelect = from.querySelectorAll('select')
      forEach(allSelect, value => {
        // console.log('type', value.type)
        // console.log('keyz', value.name)
        // console.log('value', value.value)
        result.push(this.getResult(value.name, value.value))
      })

      const allTextarea = from.querySelectorAll('textarea')
      // console.log('al', allTextarea)
      forEach(allTextarea, value => {
        // console.log('type', value.type)
        // console.log('keyz', value.name)
        // console.log('value', value.value)
        result.push(this.getResult(value.name, value.value))
      })
      // console.log(result)
      result = filter(result, value => {
        return value.questionId && value.result !== ''
      })
      return result
    }

    getResult(name: string, value: string) {
      const a = split(name, '_')

      return {
        id: a[1],
        questionId: a[2],
        key: a[3],
        result: value
      }
    }

    created() {
      this.init()
    }

    // mounted() {
    //   // this.addEventToQuestions()
    //   this.init()
    // }

    // beforeDestroy() {
    //   this.removeEventFromQuestions()
    // }
  }
