
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import QuizAttempt from '../../api/quizAttempt'
  import getApi from '@/api'
  import { AttemptResultInterface, QuestionInterface } from '@/interface/BaseInterface'
  // import AttemptResult from './AttemptResult.vue'
  import ViewAttempt from './ViewAttempt.vue'
  import { trim, filter, kebabCase, includes, unionBy, sortBy, size } from 'lodash'

  const quizAttempt: QuizAttempt = getApi('quizAttempt')

  @Component({ components: { ViewAttempt } })
  export default class AllAttempt extends Vue {
    @Prop({ required: true, type: Array }) listQuestion!: QuestionInterface[]
    @Prop({ required: false, type: String, default: 'other' }) type!: 'reading' | 'listening' | 'other' | 'writing' | 'speaking'
    @Prop({ required: false, type: Number, default: 0 }) numberQuestions!: number

    search = ''

    data: AttemptResultInterface[] = []

    filter = ''

    lastAttempt = false

    unfulfilled = false

    listUserNotAttempt = []

    isSend = false

    get dataFilter() {
      if (this.unfulfilled) {
        return this.listUserNotAttempt
      }
      let result: AttemptResultInterface[] = this.data

      result = sortBy(result, value => -value.timeStart)

      if (trim(this.search) !== '') {
        result = filter(result, value => {
          const name = value.user?.firstName + ' ' + value.user?.lastName
          return includes(kebabCase(name), kebabCase(this.search))
        })
      }

      if (this.filter !== '') {
        result = filter(result, value => {
          return value.status === this.filter
        })
      }

      if (this.lastAttempt) {
        result = unionBy(result, 'userId')
      }

      return result
    }

    async init() {
      try {
        this.data = await quizAttempt.allList(this.quizId)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    handleCommand(command: string) {
      this.filter = command === 'all' ? '' : command
    }

    handleViewDetail(attempt: AttemptResultInterface) {
      if (this.checkRole(['attempt'], 'QUIZ')) {
        this.$router.push({
          name: 'quizResultByUser',
          params: { id: this.courseId, quizId: this.quizId, attemptId: attempt._id, userId: attempt.userId }
        })
      } else {
        this.$router.push({ name: 'quizResult', params: { id: this.courseId, quizId: this.quizId, attemptId: attempt._id } })
      }
    }

    handleView(attempt: AttemptResultInterface) {
      this.$emit('view', attempt)
    }

    async handleCheckAllChange(val: boolean) {
      console.log(val)
      if (val && size(this.listUserNotAttempt) === 0) {
        this.listUserNotAttempt = await quizAttempt.listUserNotAttmept(this.courseId, this.quizId)
      }
    }

    async sendEmailForUserNotAttmept() {
      try {
        this.isSend = true
        await quizAttempt.sendEmailForUserNotAttmept(this.courseId, this.quizId)
        this.$message.success(this.$t('sendEmailSuccess') as string)
        this.isSend = false
      } catch (error) {
        console.log(error)
        this.isSend = false
      }
    }

    created() {
      this.init()
    }
  }
