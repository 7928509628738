
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import QuizAttempt from '../../api/quizAttempt'
  import getApi from '@/api'
  import { AttemptResultInterface, QuestionInterface } from '@/interface/BaseInterface'
  import ViewAttempt from './ViewAttempt.vue'

  const quizAttempt: QuizAttempt = getApi('quizAttempt')

  @Component({ components: { ViewAttempt } })
  export default class MyAttempt extends Vue {
    @Prop({ required: true, type: Array }) listQuestion!: QuestionInterface[]
    @Prop({ required: false, type: Boolean, default: true }) showAnswer!: boolean
    @Prop({ required: false, type: String, default: 'other' }) type!: 'reading' | 'listening' | 'other' | 'writing' | 'speaking'
    @Prop({ required: false, type: Number, default: 0 }) numberQuestions!: number

    data: AttemptResultInterface[] = []

    async init() {
      try {
        this.data = await quizAttempt.list(this.quizId)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    handleViewDetail(attempt: AttemptResultInterface) {
      this.$router.push({ name: 'quizResult', params: { id: this.courseId, quizId: this.quizId, attemptId: attempt._id } })
    }

    handleView(attempt: AttemptResultInterface) {
      this.$emit('view', attempt)
    }

    created() {
      this.init()
    }
  }
