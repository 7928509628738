
  import { Component, Vue } from 'vue-property-decorator'
  import QuizPreview2 from './QuizPreview2.vue'
  import { namespace } from 'vuex-class'
  import { SPopupBase } from '@/store/base'

  const tBase = namespace('base')

  @Component({ components: { QuizPreview2 } })
  export default class QuizPreviewPopup extends Vue {
    @tBase.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void
    customQuizId = ''
    isPreview = false

    setData(quizId: string, isPreview = false) {
      this.customQuizId = quizId
      this.isPreview = isPreview
    }

    reset() {
      this.customQuizId = ''
    }

    handleOpen() {
      console.log(123)
      if (this.$refs.quizPreview) {
        // @ts-ignore
        // this.$refs.quizPreview.init()
      }
    }

    handleClose() {
      this.reset()
      if (!this.isPreview) {
        this.$emit('refresh')
      }
      this.isPreview = false
    }

    handleClosePopup() {
      this.setOpenPopup({ module: 'quizPreviewPopup', isOpen: false })
    }
  }
