
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { namespace } from 'vuex-class'
  import { QuizInterface, UserInterface } from '@/interface/BaseInterface'

  const tAuth = namespace('tAuth')

  @Component
  export default class QuizTestSound extends Vue {
    @Prop({ required: true, type: Object }) quiz!: QuizInterface

    @tAuth.State('user') user!: UserInterface

    handlePlaySound() {
      const testSound = document.getElementById('quizTest') as HTMLAudioElement
      testSound?.play()
    }

    handleConfirm() {
      this.$emit('confirm')
    }

    mounted() {
      const testSound = document.getElementById('quizTest') as HTMLAudioElement
      testSound?.pause()
    }

    beforeDestroy() {
      const testSound = document.getElementById('quizTest') as HTMLAudioElement
      testSound?.pause()
    }
  }
